define("discourse/plugins/discourse-user-notes/discourse-user-notes/templates/connectors/after-reviewable-post-user/show-user-notes-on-flags", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.userNotesCount}}
    <DButton
      @translatedTitle={{this.userNotesTitle}}
      @action={{action "showUserNotes"}}
      class="btn btn-flat"
    >
      {{#if this.emojiEnabled}}
        <img
          src={{this.emojiUrl}}
          title={{this.userNotesTitle}}
          alt=""
          class="emoji"
        />
      {{else}}
        {{d-icon "pen-to-square"}}
      {{/if}}
    </DButton>
  {{/if}}
  */
  {
    "id": "9cEKqKML",
    "block": "[[[41,[30,0,[\"userNotesCount\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn btn-flat\"]],[[\"@translatedTitle\",\"@action\"],[[30,0,[\"userNotesTitle\"]],[28,[37,2],[[30,0],\"showUserNotes\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"emojiEnabled\"]],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,0,[\"emojiUrl\"]]],[15,\"title\",[30,0,[\"userNotesTitle\"]]],[14,\"alt\",\"\"],[14,0,\"emoji\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[\"pen-to-square\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"img\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-user-notes/discourse-user-notes/templates/connectors/after-reviewable-post-user/show-user-notes-on-flags.hbs",
    "isStrictMode": false
  });
});