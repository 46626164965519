define("discourse/plugins/discourse-user-notes/discourse/initializers/enable-user-notes", ["exports", "discourse/lib/icon-library", "discourse/lib/plugin-api", "discourse/plugins/discourse-user-notes/discourse-user-notes/lib/user-notes"], function (_exports, _iconLibrary, _pluginApi, _userNotes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "enable-user-notes",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      const currentUser = container.lookup("service:current-user");
      const appEvents = container.lookup("service:app-events");
      if (!siteSettings.user_notes_enabled || !currentUser?.staff) {
        return;
      }
      const store = container.lookup("service:store");
      (0, _pluginApi.withPluginApi)("0.8.15", api => {
        function widgetShowUserNotes() {
          (0, _userNotes.showUserNotes)(this.store, this.attrs.user_id, count => {
            this.sendWidgetAction("refreshUserNotes", count);
          }, {
            postId: this.attrs.id
          });
        }
        api.attachWidgetAction("post", "refreshUserNotes", function (count) {
          const cfs = this.model.user_custom_fields || {};
          cfs.user_notes_count = count;
          this.model.set("user_custom_fields", cfs);
        });
        const mobileView = api.container.lookup("service:site").mobileView;
        const loc = mobileView ? "before" : "after";
        api.decorateWidget(`poster-name:${loc}`, dec => {
          if (dec.widget.settings.hideNotes) {
            return;
          }
          const post = dec.getModel();
          if (!post) {
            return;
          }
          const ucf = post.user_custom_fields || {};
          if (ucf.user_notes_count > 0) {
            return dec.attach("user-notes-icon");
          }
        });
        api.decorateWidget(`post-avatar:after`, dec => {
          if (!dec.widget.settings.showNotes) {
            return;
          }
          const post = dec.getModel();
          if (!post) {
            return;
          }
          const ucf = post.user_custom_fields || {};
          if (ucf.user_notes_count > 0) {
            return dec.attach("user-notes-icon");
          }
        });
        api.addPostAdminMenuButton(attrs => {
          return {
            icon: "pen-to-square",
            label: "user_notes.attach",
            action: post => {
              (0, _userNotes.showUserNotes)(store, attrs.user_id, count => {
                const ucf = post.user_custom_fields || {};
                ucf.user_notes_count = count;
                post.set("user_custom_fields", ucf);
                appEvents.trigger("post-stream:refresh", {
                  id: post.id
                });
              }, {
                postId: attrs.id
              });
            },
            secondaryAction: "closeAdminMenu",
            className: "add-user-note"
          };
        });
        api.attachWidgetAction("post", "showUserNotes", widgetShowUserNotes);
        api.createWidget("user-notes-icon", {
          services: ["site-settings"],
          tagName: "span.user-notes-icon",
          click: widgetShowUserNotes,
          html() {
            if (this.siteSettings.enable_emoji) {
              return this.attach("emoji", {
                name: "memo"
              });
            } else {
              return (0, _iconLibrary.iconNode)("pen-to-square");
            }
          }
        });
      });
    }
  };
});